const ADD_EMAIL_CONFIGURATION: string = "ADD_EMAIL_CONFIGURATION";
const ADD_EMAIL_CONFIG_SUCCESS: string = "ADD_EMAIL_CONFIG_SUCCESS";
const ADD_EMAIL_CONFIG_FAILURE: string = "ADD_EMAIL_CONFIF_FAILURE";
const GET_USER_EMAIL_CONFIG: string = "GET_USER_EMAIL_CONFIG";
const GET_USER_EMAIL_CONFIG_SUCCESS: string = "GET_USER_EMAIL_CONFIG_SUCCESS";
const GET_USER_EMAIL_CONFIG_FAILURE: string = "GET_USER_EMAIL_CONFIG_FAILURE";
const DELETE_USER_CONFIG: string = "DELETE_USER_CONFIG";
const DELETE_USER_CONFIG_SUCCESS: string = "DELETE_USER_CONFIG_SUCCESS";
const DELETE_USER_CONFIG_FAILURE: string = "DELETE_USER_CONFIG_FAILURE";
const ENABLE_DELETE_DIALOG: string = "ENABLE_DELETE_DIALOG";


export const types = {
  ADD_EMAIL_CONFIGURATION,
  ADD_EMAIL_CONFIG_SUCCESS,
  ADD_EMAIL_CONFIG_FAILURE,
  GET_USER_EMAIL_CONFIG,
  GET_USER_EMAIL_CONFIG_SUCCESS,
  GET_USER_EMAIL_CONFIG_FAILURE,
  DELETE_USER_CONFIG,
  DELETE_USER_CONFIG_FAILURE,
  DELETE_USER_CONFIG_SUCCESS,
  ENABLE_DELETE_DIALOG,
  
};
