// @ts-nocheck
export const thumbnailDefault =
  "https://dubb.sfo2.digitaloceanspaces.com/videos/2019-12-14/8cf4b1479cf7010087580d802818402b/1576283646_preview.png";

// video tab constants
export const PLAYER = "Player";
export const CTA = "CTA";
export const BACKGROUND = "Background";
export const DEFAULT_SET = "Set as default controls color for all videos";
export const CUSTOM_BACKGROUND =
  "Add a custom background image to your video page.";
export const DESCRIPTION = "Description";
export const ADD_TEXT = "Add text under your video";
export const ADD_TAGS = "Add tags for internal organization";
export const TAG = "Tag";
export const SAMPLE_USE = "Sample Use Case - Scheduling Meetings";
export const ZERO = "0";
export const DASH = "|";
export const MP4 = "MP4";
export const SEND_SHARE = "Share & Send";
export const PRIVACY = "Privacy";
export const COMMENTS = "Comments";
export const OPTION_1 = "Feature: Publicly visible on your profile page";
export const OPTION_2 = "Shareable: Send via link, email or social";
export const OPTION_3 = "Draft: Only visible in my account";
export const START_RECORDING = "Start Recording";
export const STOP_RECORDING = "Stop Recording";
export const DETAILS = "DETAILS";
export const ANALYTICS = "ANALYTICS";
export const EDITING = "EDITING";
export const DESIGN = "DESIGN";
export const VIDEO_REPLIES = "VIDEO REPLIES";
export const TAB = "Tab 2";

//Profile screen constants
export const PROFILE_PHOTO = "PROFILE PHOTO";
export const UPLOAD_DESCRIPTION = "Upload a profile photo of you";
export const SELECT_NEW_PHOTO = "SELECT NEW PHOTO";
export const YOUR_PROFILE = "YOUR PROFILE";
export const FIRSTNAME = "First Name";
export const LASTNAME = "Last Name";
export const USERNAME = "User Name";
export const E_MAIL = "E-Mail Address";
export const MOBILE_NUMBER = "Mobile Number";
export const TIMEZONE = "Timezone";
export const BUSINESS_PHONE = "Business Phone";
export const WEB_ADDRESS = "Web Address";
export const TITLE = "Title";
export const AFFILIATE = "Affiliate URL";
export const PROFILE_DESCRIPTION =
  "Are you a member of the Dubb Affiliate Program? Enter your referral ID here to replace your referral link with your affiliate link to earn recurring commissions. Signup at";
export const PROFILE_URL = "earn.dubb.com.";
export const UPDATE = "Update";
export const LINK_DUBB_ACCOUNT = "LINK DUBB ACCOUNTS";
export const EMAIL = "Email";
export const SEND = "Send";
export const PROFILE_PIC_INSTRUCTIONS =
  "Upload profile picture with dimensions max 250 x 250 pixels and less then 1 Mb";

//Signin screen constants
export const EMAIL_ADDRESS = "E-MAIL ADDRESS";
export const EMAIL_ERROR = "Enter your email";
export const EMAIL_INVALID = "Invalid email";
export const PASSWORD = "PASSWORD";
export const PASSWORD_ERROR = "Password Not Empty";
export const LOGIN = "Login";
export const DONT_HAVE_ACCOUNT = "Don't Have Account?";
export const REGISTER = "Register";
export const SIGNUP_TO = "Signup to";
export const SIGNIN_TO = "Login to";
export const VIIDEON = "viideon";
export const LOGIN_TO_ACCOUNT = "Login to your account";
export const DONT_HAVE_ACCOUNT_YET = "Don't have an account yet?";
export const CREATE_NEW = "Create one here";
export const SIGN_UP_HERE = "Sign up here";
export const ENTER_DETAILS = "Enter Your details below to continue";
export const RESEND_VERIFICATION_EMAIL_TEXT =
  "We have sent you a verification email. Please verify your account from the link given in email. If you did not receive verification email yet, you may";

//signin constants
export const FIRSTNAME_ERROR = "First Name Not Empty";
export const LASTNAME_ERROR = "Last Name Not Empty";
export const USERNAME_ERROR = "UserName Not Empty";
export const EMAIL_BUSINESS = "BUSINESS E-MAIL";
export const CONFIRM_PASSWORD = "Confirm Password";
export const CONFIRM_ERROR = "Confirm Password Not Empty";
export const PASSWORD_MATCHING = "Password Not Matched";
export const NEXT = "Next";
export const ALREADY_HAD_ACCOUNT = "Already have an account?";
export const LOGIN_HERE = "Login here";

//Video Upload constants
export const CREATE_VIDEO = "CREATE A VIDEO";
export const VIDEO_TITLE = "Video Title";
export const RECORD_AND_SHARE = "Record and share a personalized video.";
export const UPLOAD_FROM_COMPUTER = "Upload from Computer";
export const RECORD_WITH_CAMERA = "Record With Camera";
export const CLICK_AND_DRAG = "Click or Drag File Here to Upload";
export const SEND_THROUGH_EMAIL = "Send Through Email";
export const SENDER_ADDRESS = "Reciever Email";
export const SAVE_VIDEO = "Save Video";

//home
export const HEADER = "VIIDEON";

//campaign Recording constants

export const INTRO_INSTRUCTION_HEADING = "INTRODUCTION";
export const INTRO_INSTRUCTION_LENGHT = "Suggested Length: 10 to 15 Seconds.";
export const INTRO_INSTRUCTION =
  "Get their attention first, then introduce yourself in connection with the solution that you will offer in the second shot - The Message.";
export const INTRO_INSTRUCTION_EXAMPLE_ONE =
  "Did you know that…( mention something of interest to your target audience - get their attention with something unique or, even alarming).";
export const INTRO_INSTRUCTION_EXAMPLE_TWO =
  "Are you looking for…(mention a NEED that you know your target audience has a 'pain point' ";
export const INTRO_INSTRUCTION_EXAMPLE_THREE =
  "My name is ________ and we specialize in (solving the problem you mentioned in the first portion)";

export const MESSAGE_INSTRUCTION_HEADING = "THE MESSAGE";
export const MESSAGE_INSTRUCTION =
  "Offer a solution to the problem you brought up in your attention getting introduction. Give one piece of free advice that sets you up as the obvious choice to execute that solution.";
export const MESSAGE_INSTRUCTION_LENGHT = "Suggested Length: 30 to 45 Seconds.";
export const MESSAGE_INSTRUCTION_EXAMPLE_ONE =
  "By doing XYZ you can solve that problem immediately. Would you like to learn how you can do XYZ ?";
export const MESSAGE_INSTRUCTION_EXAMPLE_TWO =
  "Following a five step approach to this problem, you can easily solve it. The first step is XYZ.";
export const MESSAGE_INSTRUCTION_EXAMPLE_THREE =
  "Tell your audience about any significant achievements";

export const CONCLUSION_INSTRUCTION_HEADING = "CALL TO ACTION ";
export const CONCLUSION_INSTRUCTION =
  "Ask them to take action by contacting you and provide the contact information.";
export const CONCLUSION_INSTRUCTION_EXAMPLE_ONE =
  "Call me at 555-321-1212 and I will show you how to execute the next steps.";
export const CONCLUSION_INSTRUCTION_EXAMPLE_TWO =
  "Call me for a free consultation where I can show you how to solve this problem.";
export const CONCLUSION_INSTRUCTION_Q_FOUR =
  " Tell your audience about why you are the right choice for patients";

export const availableTheme = [
  { 
    name: "Spread",
    avatar: require("../assets/email-theme/Spread.png")
  },
  {
    name: "Streamlined",
    avatar: require("../assets/email-theme/Streamlined.png")
  },
  {
    name: "Simple Blue",
    avatar: require("../assets/email-theme/SimpleBlue.png")
  },
  { 
    name: "Sleek",
    avatar: require("../assets/email-theme/Sleek.png")
  },
  {
    name: "Social Business",
    avatar: require("../assets/email-theme/SocialBusiness.png")
  },
  {
    name: "Social Impact",
    avatar: require("../assets/email-theme/SocialImpact.png")
  },
  {
    name: "Clasic Dark",
    avatar: require("../assets/email-theme/ClassicDark.png")
  },
  {
    name: "Corporate Light",
    avatar: require("../assets/email-theme/CorporateLight.png")
  },
  {
    name: "Modern Simple",
    avatar: require("../assets/email-theme/ModernSimple.png")
  },
  {
    name: "Ocean",
    avatar: require("../assets/email-theme/Ocean.png")
  }
];

export function isVideo(file) {
  var ext = getExtension(file.type);
  switch (ext.toLowerCase()) {
    case "video/m4v":
    case "video/avi":
    case "video/mpg":
    case "video/mp4":
      return true;
  }
  return false;
}
function getExtension(filename) {
  var parts = filename.split(".");
  return parts[parts.length - 1];
}
