const ADD_ASSET: string = "ADD_ASSET";
const ADD_ASSET_SUCCESS: string = "ADD_ASSET_SUCCESS";
const ADD_ASSET_FAILURE: string = "ADD_ASSET_FAILURE";
const GET_ASSETS: string = "GET_ASSETS";
const GET_ASSETS_SUCCESS: string = "GET_ASSETS_SUCCESS";
const GET_ASSETS_FAILURE: string = "GET_ASSETS_FAILURE";
const DELETE_ASSET: string = "DELETE_ASSET";
const DELETE_ASSET_SUCCESS: string = "DELETE_ASSET_SUCCESS";
const DELETE_ASSET_FAILURE: string = "DELTE_ASSET_FAILURE";
const ADD_MUSIC: string = "ADD_MUSIC";
const ADD_MUSIC_SUCCESS: string = "ADD_MUSIC_SUCCESS";
const ADD_MUSIC_FAILURE: string = "ADD_MUSIC_FAILURE";
const GET_MUSIC: string = "GET_MUSIC";
const GET_MUSIC_SUCCESS: string = "GET_MUSIC_SUCCESS";
const GET_MUSIC_FAILURE: string = "GET_MUSICE_FAILURE";

const GET_PUBLIC_MUSIC: string = "GET_PUBLIC_MUSIC";
const GET_PUBLIC_MUSIC_SUCCESS: string = "GET_PUBLIC_MUSIC_SUCCESS";
const GET_PUBLIC_MUSIC_FAILURE: string = "GET_PUBLIC_MUSICE_FAILURE";

const DELETE_MUSIC: string = "DELETE_MUSIC";
const DELETE_MUSIC_SUCCESS: string = "DELETE_MUSIC_SUCCESS";
const DELETE_MUSIC_FAILURE: string = "DELETE_MUSIC_FAILURE";

const GET_CAMPAIGN_TEMPLATES: string = "GET_CAMPAIGN_TEMPLATES";
const GET_CAMPAIGN_TEMPLATES_SUCCESS: string = "GET_CAMPAIGN_TEMPLATE_SUCCESS";
const GET_CAMPAIGN_TEMPLATES_FAILURE: string = "GET_CAMPAIGN_TEMPLATES_FAILURE";

const GET_INDUSTRIES: string = "GET_INDUSTRIES";
const GET_INDUSTRIES_SUCCESS: string = "GET_INDUSTRIES_SUCCESS";
const GET_INDUSTRIES_FAILURE: string = "GET_INDUSTRIES_FAILURE";

const PREVIEW_REQUEST: string = "PREVIEW_REQUEST";
const PREVIEW_SUCCESS: string = "PREVIEW_SUCCESS";
const PREVIEW_FAILURE: string = "PREVIEW_FAILURE";

const SAVE_SETTINGS_REQUEST: string = "SAVE_SETTINGS_REQUEST";
const SAVE_SETTINGS_SUCCESS: string = "SAVE_SETTINGS_SUCCESS";
const SAVE_SETTINGS_FAILURE: string = "SAVE_SETTINGS_FAILURE";

const SELECT_INDUSTRY: string = "SELECT_INDUSTRY";

export const types = {
  ADD_ASSET,
  ADD_ASSET_SUCCESS,
  ADD_ASSET_FAILURE,
  GET_ASSETS,
  GET_ASSETS_SUCCESS,
  GET_ASSETS_FAILURE,
  DELETE_ASSET,
  DELETE_ASSET_SUCCESS,
  DELETE_ASSET_FAILURE,
  ADD_MUSIC,
  ADD_MUSIC_SUCCESS,
  ADD_MUSIC_FAILURE,
  GET_MUSIC,
  GET_MUSIC_SUCCESS,
  GET_MUSIC_FAILURE,
  GET_PUBLIC_MUSIC,
  GET_PUBLIC_MUSIC_SUCCESS,
  GET_PUBLIC_MUSIC_FAILURE,
  DELETE_MUSIC,
  DELETE_MUSIC_SUCCESS,
  DELETE_MUSIC_FAILURE,
  GET_CAMPAIGN_TEMPLATES,
  GET_CAMPAIGN_TEMPLATES_SUCCESS,
  GET_CAMPAIGN_TEMPLATES_FAILURE,
  GET_INDUSTRIES,
  GET_INDUSTRIES_SUCCESS,
  GET_INDUSTRIES_FAILURE,
  SELECT_INDUSTRY,
  PREVIEW_REQUEST,
  PREVIEW_SUCCESS,
  PREVIEW_FAILURE,
  SAVE_SETTINGS_REQUEST,
  SAVE_SETTINGS_SUCCESS,
  SAVE_SETTINGS_FAILURE,
};
