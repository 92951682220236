const styles = {
  headerCardWrap: {
    display: "flex"
  },
  headerCardOne: {
    padding: "15px",
    backgroundColor: "#46adf0"
  },
  headerCardTwo: {
    padding: "15px",
    backgroundColor: "#44be8b"
  },
  headerCardThree: {
    padding: "15px",
    backgroundColor: "#936ee0"
  },
  headerCardFour: {
    padding: "15px",
    backgroundColor: "#f9686b"
  }
};
export default styles;
